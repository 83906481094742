<template>
    <!-- Generator: Adobe Illustrator 24.2.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
    <div :style="'width:' + (width > 0 ? width + 'px' : 'auto')" :class="'tawny-logo-svg'">

        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px" y="0px"
            :fill="color != '' ? color : '#f08a69'"
            :viewBox="viewBox"
            style="enable-background:new 0 0 2663 799;"
            xml:space="preserve">

            <!-- TAWNY text below owl -->
            <g id="TAWNY" v-if="showText && textBelowOwl" transform="translate(-630, 750) scale(.6, .6)">

                <g id="text">
                    <path class="st0"
                        d="M1021.33,299.4H915.3v292.5H866V299.4H759.5V258h261.83L1021.33,299.4L1021.33,299.4z" />
                    <path class="st0" d="M1335.47,591.9h-38.14c-4.34,0-7.91-1.08-10.7-3.25c-2.78-2.17-4.81-4.96-6.05-8.37l-29.53-77.43h-147.19
                    l-29.53,77.43c-1.09,2.95-3.1,5.61-6.05,8.03c-2.95,2.4-6.51,3.6-10.69,3.6h-38.14l133-333.9h49.99L1335.47,591.9z
                    M1237.57,467.51l-49.53-129.52c-3.57-8.84-7.13-20-10.7-33.49c-1.71,6.67-3.44,12.86-5.23,18.62
                    c-1.78,5.73-3.53,10.85-5.22,15.34l-49.3,129.05H1237.57z" />
                    <path class="st0" d="M1815.85,258l-104.16,333.9h-44.65l-81.37-247.63c-1.86-4.96-3.49-10.93-4.89-17.9
                    c-1.71,7.13-3.33,13.09-4.88,17.9l-82.32,247.63h-44.42L1345,258h41.39c4.34,0,7.99,1.04,10.93,3.13
                    c2.94,2.09,4.88,4.94,5.81,8.49l65.81,223.45c1.23,4.35,2.36,9.08,3.36,14.19c1.01,5.12,2.05,10.47,3.14,16.06
                    c1.09-5.59,2.22-10.93,3.37-16.06c1.16-5.1,2.52-9.84,4.06-14.19l75.35-223.45c0.92-3.09,2.91-5.81,5.92-8.13
                    c3.03-2.34,6.63-3.5,10.81-3.5h14.19c4.34,0,7.91,1.08,10.7,3.25c2.78,2.17,4.8,4.96,6.05,8.37l75.1,223.45
                    c2.78,8.54,5.26,18.23,7.44,29.08c1.87-10.71,3.88-20.38,6.06-29.08l66.03-223.45c0.77-3.09,2.7-5.81,5.81-8.13
                    c3.09-2.34,6.75-3.5,10.93-3.5h38.59V258z" />
                    <path class="st0"
                        d="M2159.98,258v333.9h-25.1c-3.88,0-7.09-0.69-9.65-2.09c-2.56-1.4-5.16-3.64-7.78-6.75l-188.58-243.91
                    c0.3,4.19,0.57,8.29,0.81,12.32c0.22,4.04,0.35,7.84,0.35,11.4V591.9h-43.49V258h25.59c4.33,0,7.58,0.51,9.76,1.5
                    c2.17,1.02,4.57,3.29,7.21,6.87l189.04,244.14c-0.47-4.49-0.81-8.86-1.06-13.13c-0.22-4.27-0.35-8.25-0.35-11.97V258H2159.98z" />
                    <path class="st0" d="M2395.76,459.83V591.9h-49.3V459.83L2224.16,258h43.49c4.33,0,7.82,1.04,10.47,3.13
                    c2.62,2.09,4.8,4.86,6.5,8.27l72.55,124.86c2.93,5.73,5.61,11.16,8.01,16.28c2.42,5.1,4.55,10.14,6.4,15.1
                    c1.71-4.96,3.68-10,5.93-15.1c2.24-5.12,4.84-10.55,7.78-16.28l72.31-124.86c1.56-2.8,3.7-5.39,6.4-7.8
                    c2.7-2.4,6.16-3.6,10.34-3.6h43.71L2395.76,459.83z" />
                </g>

                <!-- registered small (for big scaled logos) (keep .6 parent scale) -->
                <g id="registered-icon" v-if="showRegisteredOnText && registeredScale === 'small'">
                    <path class="st1" d="M2651,224.7c0,28.18-21.66,50.3-49.85,50.3c-27.89,0-50.15-22.12-50.15-50.3c0-27.57,22.26-49.7,50.15-49.7
                    C2629.34,175,2651,197.12,2651,224.7z M2563.46,224.7c0,22.12,16.02,39.7,37.98,39.7c21.37,0,37.09-17.57,37.09-39.39
                    c0-22.12-15.73-40-37.39-40S2563.46,202.88,2563.46,224.7z M2593.43,250.76h-11.27v-49.7c4.45-0.91,10.68-1.52,18.7-1.52
                    c9.2,0,13.35,1.52,16.91,3.64c2.67,2.12,4.75,6.06,4.75,10.91c0,5.46-4.15,9.7-10.09,11.51v0.61c4.75,1.82,7.42,5.45,8.9,12.12
                    c1.49,7.58,2.37,10.61,3.56,12.42h-12.17c-1.48-1.82-2.37-6.36-3.86-12.12c-0.89-5.45-3.86-7.88-10.09-7.88h-5.34L2593.43,250.76
                    L2593.43,250.76z M2593.73,222.57h5.34c6.23,0,11.28-2.12,11.28-7.27c0-4.54-3.26-7.57-10.39-7.57c-2.97,0-5.04,0.3-6.23,0.61
                    L2593.73,222.57L2593.73,222.57z" />
                </g>

                <!-- registered large (rescle the .6 parent scale) -->
                <g id="registered-icon" v-if="showRegisteredOnText && registeredScale === 'large'"
                    transform="scale(1.6) translate(-950, -100)">
                    <path class="st1" d="M2651,224.7c0,28.18-21.66,50.3-49.85,50.3c-27.89,0-50.15-22.12-50.15-50.3c0-27.57,22.26-49.7,50.15-49.7
                    C2629.34,175,2651,197.12,2651,224.7z M2563.46,224.7c0,22.12,16.02,39.7,37.98,39.7c21.37,0,37.09-17.57,37.09-39.39
                    c0-22.12-15.73-40-37.39-40S2563.46,202.88,2563.46,224.7z M2593.43,250.76h-11.27v-49.7c4.45-0.91,10.68-1.52,18.7-1.52
                    c9.2,0,13.35,1.52,16.91,3.64c2.67,2.12,4.75,6.06,4.75,10.91c0,5.46-4.15,9.7-10.09,11.51v0.61c4.75,1.82,7.42,5.45,8.9,12.12
                    c1.49,7.58,2.37,10.61,3.56,12.42h-12.17c-1.48-1.82-2.37-6.36-3.86-12.12c-0.89-5.45-3.86-7.88-10.09-7.88h-5.34L2593.43,250.76
                    L2593.43,250.76z M2593.73,222.57h5.34c6.23,0,11.28-2.12,11.28-7.27c0-4.54-3.26-7.57-10.39-7.57c-2.97,0-5.04,0.3-6.23,0.61
                    L2593.73,222.57L2593.73,222.57z" />
                </g>
            </g>


            <!-- TAWNY next to owl -->
            <g id="TAWNY" v-if="showText && !textBelowOwl">

                <g id="text">
                    <path class="st0"
                        d="M1021.33,299.4H915.3v292.5H866V299.4H759.5V258h261.83L1021.33,299.4L1021.33,299.4z" />
                    <path class="st0" d="M1335.47,591.9h-38.14c-4.34,0-7.91-1.08-10.7-3.25c-2.78-2.17-4.81-4.96-6.05-8.37l-29.53-77.43h-147.19
                    l-29.53,77.43c-1.09,2.95-3.1,5.61-6.05,8.03c-2.95,2.4-6.51,3.6-10.69,3.6h-38.14l133-333.9h49.99L1335.47,591.9z
                    M1237.57,467.51l-49.53-129.52c-3.57-8.84-7.13-20-10.7-33.49c-1.71,6.67-3.44,12.86-5.23,18.62
                    c-1.78,5.73-3.53,10.85-5.22,15.34l-49.3,129.05H1237.57z" />
                    <path class="st0" d="M1815.85,258l-104.16,333.9h-44.65l-81.37-247.63c-1.86-4.96-3.49-10.93-4.89-17.9
                    c-1.71,7.13-3.33,13.09-4.88,17.9l-82.32,247.63h-44.42L1345,258h41.39c4.34,0,7.99,1.04,10.93,3.13
                    c2.94,2.09,4.88,4.94,5.81,8.49l65.81,223.45c1.23,4.35,2.36,9.08,3.36,14.19c1.01,5.12,2.05,10.47,3.14,16.06
                    c1.09-5.59,2.22-10.93,3.37-16.06c1.16-5.1,2.52-9.84,4.06-14.19l75.35-223.45c0.92-3.09,2.91-5.81,5.92-8.13
                    c3.03-2.34,6.63-3.5,10.81-3.5h14.19c4.34,0,7.91,1.08,10.7,3.25c2.78,2.17,4.8,4.96,6.05,8.37l75.1,223.45
                    c2.78,8.54,5.26,18.23,7.44,29.08c1.87-10.71,3.88-20.38,6.06-29.08l66.03-223.45c0.77-3.09,2.7-5.81,5.81-8.13
                    c3.09-2.34,6.75-3.5,10.93-3.5h38.59V258z" />
                    <path class="st0"
                        d="M2159.98,258v333.9h-25.1c-3.88,0-7.09-0.69-9.65-2.09c-2.56-1.4-5.16-3.64-7.78-6.75l-188.58-243.91
                    c0.3,4.19,0.57,8.29,0.81,12.32c0.22,4.04,0.35,7.84,0.35,11.4V591.9h-43.49V258h25.59c4.33,0,7.58,0.51,9.76,1.5
                    c2.17,1.02,4.57,3.29,7.21,6.87l189.04,244.14c-0.47-4.49-0.81-8.86-1.06-13.13c-0.22-4.27-0.35-8.25-0.35-11.97V258H2159.98z" />
                    <path class="st0" d="M2395.76,459.83V591.9h-49.3V459.83L2224.16,258h43.49c4.33,0,7.82,1.04,10.47,3.13
                    c2.62,2.09,4.8,4.86,6.5,8.27l72.55,124.86c2.93,5.73,5.61,11.16,8.01,16.28c2.42,5.1,4.55,10.14,6.4,15.1
                    c1.71-4.96,3.68-10,5.93-15.1c2.24-5.12,4.84-10.55,7.78-16.28l72.31-124.86c1.56-2.8,3.7-5.39,6.4-7.8
                    c2.7-2.4,6.16-3.6,10.34-3.6h43.71L2395.76,459.83z" />
                </g>


                <!-- registered small (for big scaled logos) -->
                <g id="registered-icon" v-if="showRegisteredOnText && registeredScale === 'small'"
                    transform="scale(.6) translate(1700 180)">
                    <path class="st1" d="M2651,224.7c0,28.18-21.66,50.3-49.85,50.3c-27.89,0-50.15-22.12-50.15-50.3c0-27.57,22.26-49.7,50.15-49.7
                    C2629.34,175,2651,197.12,2651,224.7z M2563.46,224.7c0,22.12,16.02,39.7,37.98,39.7c21.37,0,37.09-17.57,37.09-39.39
                    c0-22.12-15.73-40-37.39-40S2563.46,202.88,2563.46,224.7z M2593.43,250.76h-11.27v-49.7c4.45-0.91,10.68-1.52,18.7-1.52
                    c9.2,0,13.35,1.52,16.91,3.64c2.67,2.12,4.75,6.06,4.75,10.91c0,5.46-4.15,9.7-10.09,11.51v0.61c4.75,1.82,7.42,5.45,8.9,12.12
                    c1.49,7.58,2.37,10.61,3.56,12.42h-12.17c-1.48-1.82-2.37-6.36-3.86-12.12c-0.89-5.45-3.86-7.88-10.09-7.88h-5.34L2593.43,250.76
                    L2593.43,250.76z M2593.73,222.57h5.34c6.23,0,11.28-2.12,11.28-7.27c0-4.54-3.26-7.57-10.39-7.57c-2.97,0-5.04,0.3-6.23,0.61
                    L2593.73,222.57L2593.73,222.57z" />
                </g>

                <!-- registered large -->
                <g id="registered-icon" v-if="showRegisteredOnText && registeredScale === 'large'"
                    transform="translate(20 -40)">
                    <path class="st1" d="M2651,224.7c0,28.18-21.66,50.3-49.85,50.3c-27.89,0-50.15-22.12-50.15-50.3c0-27.57,22.26-49.7,50.15-49.7
                    C2629.34,175,2651,197.12,2651,224.7z M2563.46,224.7c0,22.12,16.02,39.7,37.98,39.7c21.37,0,37.09-17.57,37.09-39.39
                    c0-22.12-15.73-40-37.39-40S2563.46,202.88,2563.46,224.7z M2593.43,250.76h-11.27v-49.7c4.45-0.91,10.68-1.52,18.7-1.52
                    c9.2,0,13.35,1.52,16.91,3.64c2.67,2.12,4.75,6.06,4.75,10.91c0,5.46-4.15,9.7-10.09,11.51v0.61c4.75,1.82,7.42,5.45,8.9,12.12
                    c1.49,7.58,2.37,10.61,3.56,12.42h-12.17c-1.48-1.82-2.37-6.36-3.86-12.12c-0.89-5.45-3.86-7.88-10.09-7.88h-5.34L2593.43,250.76
                    L2593.43,250.76z M2593.73,222.57h5.34c6.23,0,11.28-2.12,11.28-7.27c0-4.54-3.26-7.57-10.39-7.57c-2.97,0-5.04,0.3-6.23,0.61
                    L2593.73,222.57L2593.73,222.57z" />
                </g>
            </g>

            <!-- TAWNY owl -->
            <g id="Logo" v-if="showOwl">
                <g id="right-eye">
                    <path id="SVGID_1_" class="st0" d="M450.75,191.77c-25.29,0-45.78,20.52-45.78,45.81c0,25.26,20.5,45.82,45.78,45.82
                    s45.76-20.56,45.76-45.82C496.51,212.3,476.03,191.77,450.75,191.77z" />
                </g>

                <g id="left-eye">
                    <path id="SVGID_3_" class="st0" d="M241.58,191.77c25.29,0,45.78,20.52,45.78,45.81c0,25.26-20.5,45.82-45.78,45.82
                    s-45.76-20.56-45.76-45.82C195.82,212.3,216.3,191.77,241.58,191.77z" />
                </g>

                <g id="head">
                    <path class="st0"
                        d="M549.7,130.71c25.22-34.94,69.92-36.16,69.92-36.16c-20.2-17.14-62.68-27.65-105.81-11.8
                    c-10.8,3.98-52.49,10.74-56.22,11.33c-24.2,3.02-67.81,4.53-111.42,4.53s-87.22-1.51-111.42-4.53
                    c-3.73-0.58-45.42-7.35-56.22-11.33C135.4,66.9,92.91,77.42,72.72,94.55c0,0,44.7,1.22,69.92,36.16
                    c-30.25,25.4-49.49,63.52-49.49,106.1c0,76.41,61.95,138.4,138.39,138.4c27.53,0,53.18-8.06,74.74-21.93l39.82,62.09l0.07-0.1
                    l0.07,0.1l39.82-62.09c21.56,13.86,47.21,21.93,74.74,21.93c76.44,0,138.39-61.99,138.39-138.4
                    C599.19,194.23,579.95,156.11,549.7,130.71z M241.58,336.69c-52.71,0-95.46-42.74-95.46-95.45c0-52.73,42.75-95.47,95.46-95.47
                    c52.73,0,95.49,42.74,95.49,95.47C337.06,293.94,294.31,336.69,241.58,336.69z M450.75,336.69c-52.73,0-95.49-42.74-95.49-95.45
                    c0-52.73,42.76-95.47,95.49-95.47c52.71,0,95.46,42.74,95.46,95.47C546.21,293.94,503.46,336.69,450.75,336.69z" />
                </g>

                <g id="torso">
                    <path class="st0" d="M606.92,311.57c-14.68,30.93-38.27,56.8-67.45,74.31c3.98,133.68-48.22,262.67-193.29,330.71h-0.01h-0.01
                    c-145.08-68.04-197.28-197.03-193.29-330.71c-29.19-17.5-52.78-43.38-67.45-74.31c-27.56,173.38,35.21,350.41,260.75,438.48h0.01
                    h0.01C571.72,661.98,634.49,484.95,606.92,311.57z" />
                </g>

                <!-- registered small (for big scaled logos) -->
                <g id="registered-icon" v-if="showRegisteredOnOwl && registeredScale === 'small'"
                    transform="scale(.6) translate(-1500 -100)">
                    <path class="st1" d="M2651,224.7c0,28.18-21.66,50.3-49.85,50.3c-27.89,0-50.15-22.12-50.15-50.3c0-27.57,22.26-49.7,50.15-49.7
                    C2629.34,175,2651,197.12,2651,224.7z M2563.46,224.7c0,22.12,16.02,39.7,37.98,39.7c21.37,0,37.09-17.57,37.09-39.39
                    c0-22.12-15.73-40-37.39-40S2563.46,202.88,2563.46,224.7z M2593.43,250.76h-11.27v-49.7c4.45-0.91,10.68-1.52,18.7-1.52
                    c9.2,0,13.35,1.52,16.91,3.64c2.67,2.12,4.75,6.06,4.75,10.91c0,5.46-4.15,9.7-10.09,11.51v0.61c4.75,1.82,7.42,5.45,8.9,12.12
                    c1.49,7.58,2.37,10.61,3.56,12.42h-12.17c-1.48-1.82-2.37-6.36-3.86-12.12c-0.89-5.45-3.86-7.88-10.09-7.88h-5.34L2593.43,250.76
                    L2593.43,250.76z M2593.73,222.57h5.34c6.23,0,11.28-2.12,11.28-7.27c0-4.54-3.26-7.57-10.39-7.57c-2.97,0-5.04,0.3-6.23,0.61
                    L2593.73,222.57L2593.73,222.57z" />
                </g>

                <!-- registered large -->
                <g id="registered-icon" v-if="showRegisteredOnOwl && registeredScale === 'large'"
                    transform="translate(-1900 -150)">
                    <path class="st1" d="M2651,224.7c0,28.18-21.66,50.3-49.85,50.3c-27.89,0-50.15-22.12-50.15-50.3c0-27.57,22.26-49.7,50.15-49.7
                    C2629.34,175,2651,197.12,2651,224.7z M2563.46,224.7c0,22.12,16.02,39.7,37.98,39.7c21.37,0,37.09-17.57,37.09-39.39
                    c0-22.12-15.73-40-37.39-40S2563.46,202.88,2563.46,224.7z M2593.43,250.76h-11.27v-49.7c4.45-0.91,10.68-1.52,18.7-1.52
                    c9.2,0,13.35,1.52,16.91,3.64c2.67,2.12,4.75,6.06,4.75,10.91c0,5.46-4.15,9.7-10.09,11.51v0.61c4.75,1.82,7.42,5.45,8.9,12.12
                    c1.49,7.58,2.37,10.61,3.56,12.42h-12.17c-1.48-1.82-2.37-6.36-3.86-12.12c-0.89-5.45-3.86-7.88-10.09-7.88h-5.34L2593.43,250.76
                    L2593.43,250.76z M2593.73,222.57h5.34c6.23,0,11.28-2.12,11.28-7.27c0-4.54-3.26-7.57-10.39-7.57c-2.97,0-5.04,0.3-6.23,0.61
                    L2593.73,222.57L2593.73,222.57z" />
                </g>
            </g>

        </svg>
    </div>
</template>


<script lang="ts">
    import { defineComponent, onMounted, computed, watch, ref, onBeforeUnmount} from "vue";

    export default defineComponent({
        name: 'TawnyLogo',
        components: {},
        emits: [],
        props: {
            width: Number,
            showOwl: Boolean,
            showText: Boolean,
            textBelowOwl: Boolean,
            showRegisteredOnText: Boolean,
            showRegisteredOnOwl: Boolean,
            registeredScale: String,
            color: String
        },
        setup(props) {
            let viewBox = ref('0 0 2663 799');

            // hooks
            onMounted(() => {
                // only owl
                if (props.showOwl && !props.showText) {
                    viewBox.value = '0 0 800 740';

                // only text
                } else if (!props.showOwl && props.showText) {
                    viewBox.value = '700 150 2000 450';

                // text below owl
                } else if (props.textBelowOwl) {
                    viewBox.value = '-200 0 1200 1150';

                // text right to owl
                } else {
                    viewBox.value = '0 0 2663 740';
                }
            });

            return {
                viewBox
            }
        }
    })
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
    @import "@/scss/_styles.scss";

    .tawny-logo-svg {
        svg {
            width: 100%;
            height: auto;
        }

        .st2 {
            display: none;
        }

        .st3 {
            display: inline;
        }
    }
</style>
