import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
    faBackward, faCalendar, faCalendarDays, faEye, faFaceSmile, faFemale,
    faForward, faMagnifyingGlassMinus, faMagnifyingGlassPlus, faMale,
    faPause, faPerson, faPersonHalfDress, faPlay, faTransgender,
    faUsers, faVideo, faVolumeHigh, faVolumeLow, faVolumeXmark, faMap, faCircleDot,
    faShoePrints,
    faLocationDot,
    faMoneyBillTransfer,
    faPowerOff,
    faUserGroup,
    faTriangleExclamation,
    faEuroSign,
    faPercent,
    faStopwatch,
    faHourglassEnd,
    faHeart

} from '@fortawesome/free-solid-svg-icons';
import {
    faSmile
} from '@fortawesome/free-regular-svg-icons';
import Vue3TouchEvents from "vue3-touch-events";

library.add(
    faPerson,
    faTransgender,
    faPersonHalfDress,
    faUsers,
    faCalendar,
    faCalendarDays,
    faEye,
    faFaceSmile,
    faMagnifyingGlassPlus,
    faMagnifyingGlassMinus,
    faMale,
    faFemale,
    faPlay,
    faPause,
    faForward,
    faBackward,
    faVolumeXmark,
    faVolumeLow,
    faVolumeHigh,
    faVideo,
    faMap,
    faCircleDot,
    faShoePrints,
    faLocationDot,
    faMoneyBillTransfer,
    faPowerOff,
    faUserGroup,
    faTriangleExclamation,
    faEuroSign,
    faPercent,
    faStopwatch,
    faHourglassEnd,
    faHeart,
    faSmile
);

createApp(App)
    .component("font-awesome-icon", FontAwesomeIcon)
    .use(router)
    .use(Vue3TouchEvents as any)
    .mount('#app')
