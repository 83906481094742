<template>
    <div class="main"
        v-touch:press="onTouch"
        v-touch:drag="onTouch"
        v-touch:release="onTouch">

        <div class="navbar">
            <div class="head">
                <TawnyLogo class="logo"
                    :width="120"
                    :color="'#333'"
                    :showOwl="true"
                    :showText="true"
                    :textBelowOwl="false"
                    :showRegisteredOnText="true"
                    :showRegisteredOnOwl="false"
                    :registeredScale="'large'">
                </TawnyLogo>
            </div>

            <div class="controls">
                <btn class="btn round " @click="playpause()" :class="isPlaying ? ' red' : ''">
                    <font-awesome-icon class="icon" :icon="['fa', 'circle-dot']" />
                </btn>
                <btn class="btn round " @click="reset()">
                    <font-awesome-icon class="icon" :icon="['fa', 'power-off']" />
                </btn>
            </div>
        </div>

        <div class="main-content">
            <div class="header">
                <div class="title">
                    <h1>Retail Intelligence</h1>
                    <p>TAWNY Vision AI</p>
                </div>
            </div>

            <div class="dashboard">
                <div class="dashgrid">

                    <div class="dashgrid-col">
                        <div class="dashitem">
                            <div class="title red">
                                <font-awesome-icon class="icon" :icon="['fa', 'video']" />
                                <p>Live Cam | Area 3</p>
                            </div>
                            <div class="content video">
                                <video v-if="configLoaded" id="video" ref="video" nocontrols width="720" height="405"  @loadeddata="onVideoLoaded()">
                                    <source :src="videoUrl" type="video/mp4">
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </div>

                        <div class="dashitem">
                            <div class="title">
                                <font-awesome-icon class="icon" :icon="['fa', 'map']" />
                                <p>Store Map | Area 3</p>
                            </div>
                            <div class="content map">
                                <canvas
                                    class="map-canvas" id="mapCanvas" ref="mapCanvas"
                                    :width="mapW" :height="mapH" >
                                </canvas>
                            </div>
                        </div>
                     </div>


                     <div class="dashgrid-col">

                        <div class="dashitem">
                            <div class="title">
                                <font-awesome-icon class="icon" :icon="['fa', 'shoe-prints']" />
                                <p>Footfall</p>
                            </div>
                            <div class="info">
                                <p>Customer entries with individual re-entrance</p>
                            </div>
                            <div class="content data">
                                <div class="stats-group">
                                    <div class="stats">
                                        <div class="title">Current</div>
                                        <div class="num">{{ retailDataCurrent }}</div>
                                    </div>
                                    <div class="stats">
                                        <div class="title">Today</div>
                                        <div class="num">{{retailDataToday}}</div>
                                    </div>
                                    <div class="stats">
                                        <div class="title">Week</div>
                                        <div class="week-bar-chart">
                                            <div class="bar"><div class="fill" :style="{ height: 20 + '%'}"></div></div>
                                            <div class="bar"><div class="fill" :style="{ height: 24 + '%'}"></div></div>
                                            <div class="bar"><div class="fill" :style="{ height: 30 + '%'}"></div></div>
                                            <div class="bar"><div class="fill" :style="{ height: 56 + '%'}"></div></div>
                                            <div class="bar"><div class="fill" :style="{ height: 60 + '%'}"></div></div>
                                            <div class="bar"><div class="fill" :style="{ height: 80 + '%'}"></div></div>
                                            <div class="bar"><div class="fill" :style="{ height: 2 + '%'}"></div></div>
                                        </div>
                                        <div class="bar-labels w7">
                                            <p>M</p>
                                            <p>T</p>
                                            <p>W</p>
                                            <p>T</p>
                                            <p>F</p>
                                            <p>S</p>
                                            <p>S</p>
                                        </div>
                                    </div>
                                    <div class="stats">
                                        <div class="title">Avg. Dwell Time</div>
                                        <div class="num">05:32</div>
                                    </div>
                                    <div class="stats">
                                        <div class="title">Entrances/Hour</div>
                                        <div class="num">11</div>
                                    </div>
                                    <div class="stats">
                                        <div class="title">Peak Hours</div>
                                        <div class="peak-bar-chart">
                                            <div class="bar"><div class="fill" :style="{ height: 5 + '%'}"></div></div>
                                            <div class="bar"><div class="fill" :style="{ height: 20 + '%'}"></div></div>
                                            <div class="bar"><div class="fill" :style="{ height: 40 + '%'}"></div></div>
                                            <div class="bar"><div class="fill" :style="{ height: 55 + '%'}"></div></div>
                                            <div class="bar"><div class="fill" :style="{ height: 30 + '%'}"></div></div>
                                            <div class="bar"><div class="fill" :style="{ height: 30 + '%'}"></div></div>
                                            <div class="bar"><div class="fill" :style="{ height: 40 + '%'}"></div></div>
                                            <div class="bar"><div class="fill" :style="{ height: 70 + '%'}"></div></div>
                                            <div class="bar"><div class="fill" :style="{ height: 80 + '%'}"></div></div>
                                            <div class="bar"><div class="fill" :style="{ height: 90 + '%'}"></div></div>
                                            <div class="bar"><div class="fill" :style="{ height: 70 + '%'}"></div></div>
                                        </div>
                                        <div class="bar-labels w11">
                                            <p>10</p>
                                            <p>11</p>
                                            <p>12</p>
                                            <p>13</p>
                                            <p>14</p>
                                            <p>15</p>
                                            <p>16</p>
                                            <p>17</p>
                                            <p>18</p>
                                            <p>19</p>
                                            <p>20</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="dashitem">
                            <div class="title">
                                <font-awesome-icon class="icon" :icon="['fa', 'user-group']" />
                                <p>Targeting</p>
                            </div>
                            <div class="content data">
                                <div class="stats-group">
                                    <div class="stats">
                                        <div class="title l">Gender</div>
                                        <div class="pie-chart">
                                            <div class="bg">
                                                <div class="slice gender"></div>
                                            </div>
                                            <div class="labels">
                                                <div class="lb"><div class="q f"></div>female</div>
                                                <div class="lb"><div class="q a"></div>male</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="stats">
                                        <div class="title l">Age</div>
                                        <div class="pie-chart">
                                            <div class="bg">
                                                <div class="slice age"></div>
                                            </div>
                                            <div class="labels">
                                                <div class="lb"><div class="q f"></div>18-25</div>
                                                <div class="lb"><div class="q a"></div>26-35</div>
                                                <div class="lb"><div class="q b"></div>36-45</div>
                                                <div class="lb"><div class="q c"></div>46-60</div>
                                                <div class="lb"><div class="q d"></div>61-75</div>
                                                <div class="lb"><div class="q e"></div>76-99</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="dashitem">
                            <div class="title">
                                <font-awesome-icon class="icon" :icon="['fa', 'percent']" />
                                <p>Store POIs | Promotions</p>
                            </div>
                            <div class="info">
                                <p>Impressions today</p>
                            </div>
                            <div class="content data aois">
                                <div class="stats-group">
                                    <div class="stats">
                                        <div class="title">Sale</div>
                                        <div class="num">64</div>
                                    </div>
                                    <div class="stats">
                                        <div class="title">A2</div>
                                        <div class="num">7</div>
                                    </div>
                                    <div class="stats">
                                        <div class="title">A15</div>
                                        <div class="num">22</div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div class="dashitem">
                            <div class="title">
                                <font-awesome-icon class="icon" :icon="['fa', 'hourglass-end']" />
                                <p>Store POIs | Queues</p>
                            </div>
                            <div class="info">
                                <p>Avgerage waiting times on selected POIs</p>
                            </div>
                            <div class="content data aois">
                                <div class="stats-group">
                                    <div class="stats">
                                        <div class="title">Checkout</div>
                                        <div class="num">00:32</div>
                                    </div>
                                    <div class="stats">
                                        <div class="title">Changing Room</div>
                                        <div class="num danger">02:21<div class="ampel"><font-awesome-icon class="icon" :icon="['fa', 'triangle-exclamation']" /></div></div>
                                    </div>
                                    <div class="stats">
                                        <div class="title">Returns</div>
                                        <div class="num">00:21<div class="ampel"></div></div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                    <div class="dashgrid-col">
                        <div class="dashitem slim high">
                            <div class="title">
                                <font-awesome-icon class="icon" :icon="['fa', 'euro-sign']" />
                                <p>Conversion</p>
                            </div>
                            <div class="content">
                                <div class="stats-group">
                                    <div class="stats">
                                        <div class="title">Total Sales</div>
                                        <div class="num">1.520 €</div>
                                    </div>
                                    <div class="stats">
                                        <div class="title">ATV</div>
                                        <div class="num">85 €</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="dashitem slim high">
                            <div class="title">
                                <font-awesome-icon class="icon" :icon="['fa', 'heart']" />
                                <p>Atmosphere</p>
                            </div>
                            <div class="info flat">
                                <p>Average customer experience</p>
                            </div>
                            <div class="content tach">
                                <div class="stats-tachymeter">
                                    <div class="item">
                                        <span class="item__chart ">
                                            <span class="active-circle">
                                                <span class="inner-circle">
                                                </span>
                                            </span>
                                            <span class="arrow percent-70"></span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="dashitem slim high">
                            <div class="title">
                                <font-awesome-icon class="icon" :icon="['far', 'smile',]" />
                                <p>Happiness</p>
                            </div>
                            <div class="info flat">
                                <p>Number of smiles</p>
                            </div>
                            <div class="content">
                                <div class="stats-group">
                                    <div class="stats">
                                        <div class="num">37</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
  </div>
</template>

<script lang="ts">
    import { defineComponent, onMounted, onUnmounted, ref, reactive, watch, computed} from "vue";
    import TawnyLogo from "@/components/TawnyLogo.vue";
    import * as Papa from 'papaparse';
    import {RetailDataRow, RetailDataRowParsed, TawnyDataRowParsed, TawnyGraph, RetailArea, RetailDataCustomer} from "@/types/data.types";
import { faJarWheat } from "@fortawesome/free-solid-svg-icons";

    export default defineComponent({
        name: "MainView",
        components: {
            TawnyLogo
        },
        setup() {
            const configLoaded = ref(false);
            const showRightVideo = ref(false);
            const videoUrl = ref("/videos/552_output_960.mp4");
            const mapCanvas = ref<HTMLCanvasElement | null>(null);
            let mapCtx: CanvasRenderingContext2D | null = null;
            const mapW = ref(720);
            const mapH = ref(320);
            const retailAreas = ref<RetailArea[]>([]);
            const retailCSVData = ref<RetailDataRowParsed[]>([]);
            const retailDataLoaded = ref(false);
            const currentRetailData = ref<RetailDataRowParsed>();
            const retailDataCurrent = ref(0);
            const retailDataToday = ref(28);
            const retailDataCustomers = ref<RetailDataCustomer[]>([]);


            const rightVideoUrl = ref("/videos/audience.mp4");
            const width = ref(960);
            const height = ref(220);
            const widthOffset = ref(40);
            const canvas = ref<HTMLCanvasElement | null>(null);
            const barCanvas = ref<HTMLCanvasElement | null>(null);
            const video = ref<HTMLVideoElement | null>(null);
            const videoRight = ref<HTMLVideoElement | null>(null);
            const currentTime = ref(0);
            const maxTime = ref(100);
            const zoomStart = ref(0);
            const zoomEnd = ref(1);
            const dataLoaded = ref(false);
            const dragging = ref('');
            const zooming = ref('');
            const volume = ref(100);
            const csvData = ref<TawnyDataRowParsed[]>([]);
            const isPlaying = ref(false);
            let ctx: CanvasRenderingContext2D | null = null;
            let barCtx: CanvasRenderingContext2D | null = null;
            let animationFrameId = -1;
            let animationTimeBuf = 0;
            let animationTimeCount = 0;
            const currentData = ref<TawnyDataRowParsed>();


            onMounted(() => {
                loadConfig();

                if (canvas.value) {
                    ctx = canvas.value?.getContext('2d');
                    ctx!.imageSmoothingEnabled = true;
                    //animate(0);
                }

                if (barCanvas.value) {
                    barCtx = barCanvas.value?.getContext('2d');
                    barCtx!.imageSmoothingEnabled = true;
                }

                if (mapCanvas.value) {
                    mapCtx = mapCanvas.value?.getContext('2d');
                    mapCtx!.imageSmoothingEnabled = true;
                    animate(0);
                }
            });

            onUnmounted(() => {
                if (animationFrameId > -1) {
                    cancelAnimationFrame(animationFrameId);
                }
            });


            async function loadConfig() {
                try {
                    const response = await fetch('/config.json');
                    if (response.ok) {
                        const config = await response.json();
                        videoUrl.value = config.videoUrl;

                        // load retail areas
                        retailAreas.value = config.retailAreas.map((area:RetailArea) => ({
                            id: area.id,
                            name: area.name,
                            count: area.count,
                            color: area.color,
                            type: area.type,
                            xywh: area.xywh
                        }));

                        // load retail data
                        loadCSV('data.csv');

                        configLoaded.value = true;

                    } else {
                        console.error('Error loading config:', response.status);
                    }
                } catch (error) {
                    console.error('Error loading config:', error);
                }
            }


            function loadCSV(csvPath: string) {
                Papa.parse<RetailDataRow>(csvPath, {
                    download: true,
                    complete: (result:any) => {

                        const parsedData: RetailDataRowParsed[] = result.data.map((row:any) => ({
                            time: strToSeconds(row.time),
                            ids: row.ids
                        }));
                        retailCSVData.value = parsedData;
                        retailDataLoaded.value = true;
                    },
                    header: true,
                    skipEmptyLines: true,
                });
            }

            function onVideoLoaded() {
                if (video.value) {
                    video.value.addEventListener('play', () => {
                        isPlaying.value = true;
                    })
                    video.value.addEventListener('pause', () => {
                        isPlaying.value = false;
                    })
                    video.value.addEventListener('ended', () => {
                        isPlaying.value = false;
                    })
                    video.value.addEventListener('loadeddata', () => {
                        setVolume(100);
                    });
                }
                calcRetailStats();
                //playpause();
            }

            function animate(t: number) {
                const deltaT = t - animationTimeBuf;
                animationTimeBuf = t;
                animationTimeCount += deltaT
                if (animationTimeCount > 300) {
                    if (video.value) {
                        currentTime.value = video.value.currentTime;
                        maxTime.value = video.value.duration;

                        // get value from csv?
                        if (retailCSVData.value) {
                            const l = retailCSVData.value.length;

                            // run through csv
                            let i = -1;
                            retailCSVData.value.forEach((trd, index) => {
                                if (trd.time <= currentTime.value) {
                                    i = index;
                                }
                            });
                            if (i >= 0 && currentTime.value > 0) {
                                currentRetailData.value = retailCSVData.value[i]
                            }
                        }
                    }

                    // draw
                    redraw(animationTimeCount);
                    animationTimeCount = 0;

                }
                animationFrameId = requestAnimationFrame(animate);
            }

            function redraw(t: number) {
                const w = width.value;
                const h = height.value;

                if (mapCtx) {
                    const mw = mapW.value;
                    const mh = mapH.value;
                    const c = mapCtx;
                    c.fillStyle = '#f0f0f0';
                    c.fillRect(0, 0, mw, mh);

                    // draw store map elements
                    const xywhScale = 1.3
                    retailAreas.value.forEach(area => {
                        if (area != null) {
                            const fill = area.type != 'clothes';
                            const xywh = area.xywh.map((value:number, i:number) => value * xywhScale);
                            drawRoundRect(c, xywh[0], xywh[1], xywh[2], xywh[3], 6, area.color, fill);

                            if (area.name != "") {
                                c.font = '11px Arial';
                                c.fillStyle = fill ? '#111' : '#666';
                                c.textAlign = 'center';
                                c.textBaseline = 'middle';
                                c.fillText(area.name, xywh[0]+xywh[2]/2, xywh[1]+xywh[3]/2);
                            }
                        }
                        // if (area.xywh == 4) {
                        //     drawRoundRect(c, area.xywh[0], area.xywh[1], area.xywh[2], area.xywh[3], 6, area.color, true);
                        // }
                    });

                    retailDataCustomers.value.forEach((customer:RetailDataCustomer) => {
                        if (customer.x >= 0 && customer.y >= 0) {
                            c.beginPath();
                            c.arc(customer.x*xywhScale, customer.y*xywhScale, 30, 0, 2 * Math.PI, false);
                            c.fillStyle = '#45afa833';
                            c.fill();
                            c.lineWidth = 2;
                            c.strokeStyle = '#45afa8';
                            c.stroke();

                            c.beginPath();
                            c.arc(customer.x*xywhScale, customer.y*xywhScale, 10, 0, 2 * Math.PI, false);
                            c.fillStyle = '#45afa899';
                            c.fill();
                            c.stroke();
                        }
                    });


                }

                // if (ctx) {
                //     ctx.fillStyle = '#111';
                //     ctx.fillRect(0, 0, w, h);

                //     const gh = (h-40) / 3;
                //     drawGraph(ctx, w, gh, 0,
                //         [{name: 'happy', color: '#d7c03c', key: 'happy'}],
                //         0, 0.5, ['0', '1']);
                //     drawGraph(ctx, w, gh, gh+20,
                //         [{name: 'attention', color: '#d64848', key: 'attentiveRatio'}],
                //         0, 1, ['0%', '100%']);
                //     drawGraph(ctx, w, gh, 2*(gh+20),
                //         [
                //             {name: 'people', color: '#f049f0', key: 'numPeople'}
                //             //{name: 'people', color: '#555', key: 'numFemale'},
                //             //{name: 'people', color: '#888', key: 'numMale'}
                //         ],
                //         0, 200, ['0', '200']);
                // }
                // if (barCtx) {
                //     drawBar(barCtx, w, 20);
                // }
            }



            function calcRetailStats() {
                if(currentRetailData.value) {
                    const ids = currentRetailData.value.ids.split('#').filter((id:string) => id !== '')
                    ids.forEach((idStr:string) => {
                        const match = idStr.match(/[a-zA-Z]x(-?\d+)y(-?\d+)/);
                        const id = idStr.split('x')[0]
                        const x = match ? parseInt(match[1], 10) : -1;
                        const y = match ? parseInt(match[2], 10) : -1;

                        const index = retailDataCustomers.value.findIndex((customer: RetailDataCustomer) => customer.id === id);
                        if (index >= 0) {
                            if (x >=0) {
                                retailDataCustomers.value[index].x = x;
                            }
                            if (y >= 0) {
                                retailDataCustomers.value[index].y = y;
                            }

                        }else {
                            retailDataCustomers.value.push({
                                id: id,
                                x: x,
                                y: y
                            })
                        }

                        // check if theres a customer and if so change it, else add it
                        //const existing = retailDataCustomers.value.indexOf((c:RetailDataCustomer) => c.id === 'B');
                        // if (retailDataCustomers.value.filter(c => c.id === id)) {

                        // }else {
                        // }
                    });

                    //retailDataCustomers.value = customers;
                    retailDataCurrent.value = ids.length;
                    retailDataToday.value = 45 + ids.length;
                }
            }









            function drawBar(
                    ctx: CanvasRenderingContext2D,
                    w: number, h:number) {

                ctx.fillStyle = '#333';
                ctx.fillRect(0, 0, w, h);

                // draw rectangle and 2 arcs
                const r = 10
                const bStart = w * zoomStart.value + r;
                const bEnd = w * zoomEnd.value - r;
                const bW = bEnd-bStart;
                ctx.beginPath();
                ctx.fillStyle = '#666';
                ctx.fillRect(bStart, 0, bW, h);
                ctx.closePath();


                ctx.beginPath();
                ctx.arc(bStart, r, r, 0, 2 * Math.PI);
                ctx.arc(bEnd, r, r, 0, 2 * Math.PI);
                ctx.fill();
                ctx.closePath();

                ctx.beginPath();
                const cr = 8;
                ctx.fillStyle = '#333';
                ctx.arc(bStart, r, cr, 0, 2 * Math.PI);
                ctx.arc(bEnd, r, cr, 0, 2 * Math.PI);
                ctx.fill();
                ctx.closePath();


                // draw cursor
                const tx = currentTime.value / maxTime.value;
                const cx = tx * w;
                ctx.beginPath();
                ctx.strokeStyle = '#ccc';
                ctx.moveTo(cx, 2);
                ctx.lineTo(cx, h-2);
                ctx.lineWidth = 1;
                ctx.stroke();
                ctx.closePath();
            }

            function drawGraph(
                    ctx: CanvasRenderingContext2D,
                    w: number, h:number,
                    btm: number,
                    graphs: TawnyGraph[],
                    dMin: number,
                    dMax: number,
                    labels: string[]
            ) {
                const gX = widthOffset.value;

                // draw grid
                ctx.beginPath();
                ctx.strokeStyle = '#444';
                ctx.lineWidth = 1;
                ctx.setLineDash([]);
                ctx.moveTo(gX, btm+1);
                ctx.lineTo(w, btm+1);
                // ctx.moveTo(0, h/2 + btm);
                // ctx.lineTo(w, h/2 + btm);
                ctx.moveTo(gX, h + btm);
                ctx.lineTo(w, h + btm);
                ctx.stroke();
                ctx.closePath();

                ctx.beginPath();
                ctx.strokeStyle = '#333';
                ctx.lineWidth = 1;
                ctx.setLineDash([6, 3]);
                for (var i=1; i < 10; i++) {
                    //if (i != 5) {
                    ctx.moveTo(gX, i/10*h + btm);
                    ctx.lineTo(w, i/10*h + btm);
                    //}
                }
                ctx.stroke();
                ctx.closePath();

                // draw y labels
                if(labels.length > 1) {
                    ctx.font = '11px Arial';
                    ctx.fillStyle = '#666666';
                    ctx.textAlign = 'right';
                    ctx.textBaseline = 'top';
                    ctx.fillText(labels[labels.length-1], gX-4, btm);
                    ctx.textBaseline = 'bottom';
                    ctx.fillText(labels[0], gX-4, h+btm);
                }


                // draw graphs
                const l = csvData.value.length;

                // x between and l
                const xStart = Math.round(zoomStart.value * l);
                const xEnd = Math.round(zoomEnd.value * l);
                const stepPx = (w-gX)/ (xEnd - xStart);            // px per csv value

                for(var g of graphs) {
                    ctx.beginPath();
                    for (let i = xStart; i < xEnd; i++) {
                        const row = csvData.value[i];

                        const value = row[g.key];
                        //if (g.key == 'numFemale') {
                        //    row.
                        //}
                        //const value = (g.key == 'happy' ? row.happy : g.key == 'attentionRatio' ? row.attentiveRatio : row.numPeople);

                        // map x based on zoom window (0..1)
                        let x = gX + (i-xStart) * stepPx;

                        // map y from dMin..dMax to 0..1
                        const clamped = Math.max(Math.min(value, dMax), dMin);
                        const mapped = (clamped - dMin) / (dMax - dMin)
                        const y =  (1-mapped) * h + btm;
                        if (i === 0) {
                            ctx.moveTo(x, y);
                        }else {
                            ctx.lineTo(x, y);
                        }
                    }
                    ctx.setLineDash([]);
                    ctx.strokeStyle = g.color;
                    ctx.lineWidth = 2;
                    ctx.stroke();
                    ctx.closePath();
                }

                // draw cursor
                const tx = currentTime.value / maxTime.value;
                if (tx >= zoomStart.value && tx <= zoomEnd.value) {
                    const cx = (tx - zoomStart.value) / (zoomEnd.value - zoomStart.value) * (w - gX) + gX;
                    ctx.beginPath();
                    ctx.strokeStyle = '#666';
                    ctx.moveTo(cx, btm)
                    ctx.lineTo(cx, h + btm)
                    ctx.lineWidth = 2;
                    ctx.stroke();
                    ctx.closePath();
                }
            }

            function calcJump(x: number) {
                let pos = (x - widthOffset.value)/ (width.value - widthOffset.value);
                jumpVideoTo(pos);
            }

            function jumpVideoTo(pos: number) {
                if (video.value) {
                    const clamped = Math.max(Math.min(pos, 1), 0);
                    const pMin = zoomStart.value; // 0;
                    const pMax = zoomEnd.value; // 1.0;
                    const p = (pMax-pMin)*clamped + pMin;

                    const tMin = 0;
                    const tMax = maxTime.value;
                    const t = (tMax-tMin)* p + tMin;

                    video.value.pause();
                    video.value.currentTime = t;

                    if (videoRight.value) {
                        videoRight.value.pause();
                        videoRight.value.currentTime = t;
                    }
                }
            }

            function handleCanvasTap(event: MouseEvent) {
                if (canvas.value) {
                    const rect = canvas.value.getBoundingClientRect();
                    const x = event.clientX - rect.left;
                    calcJump(x)
                }
            }

            function onTouch(e: Event) {
                const target = e.target as HTMLElement;
                const src = target.id == 'canvas' ? 'graph' : target.id == 'bar-canvas' ? 'bar' : '';

                switch(e.type) {
                    case "mousedown":
                        if (src != '') {
                            onTouchStart(src);
                            onTouchMove((e as MouseEvent).clientX);
                        }
                        break;
                    case "touchstart":
                        if (src != '') {
                            onTouchStart(src);
                            onTouchMove((e as TouchEvent).targetTouches[0].clientX);
                        }
                        break;

                    case "mousemove":
                        onTouchMove((e as MouseEvent).clientX);
                        break;
                    case "touchmove":
                        (e as TouchEvent).preventDefault();
                        (e as TouchEvent).stopPropagation();
                        onTouchMove((e as TouchEvent).targetTouches[0].clientX);
                        break;

                    case "mouseup":
                        onTouchEnd();
                        break;
                    case "touchend":
                        onTouchEnd();
                        break;
                }
            }

            function onTouchStart(src: string) {
                dragging.value = src;
                zooming.value = '';
            }

            function onTouchMove(x: number) {
                if (dragging.value == 'graph' && canvas.value) {
                    const rect = canvas.value.getBoundingClientRect();
                    const offX = rect.left;
                    calcJump(x - offX);
                }else if(dragging.value == 'bar' && barCanvas.value) {
                    const rect = barCanvas.value.getBoundingClientRect();
                    const offX = rect.left;
                    calcBar(x - offX);
                }
            }
            function onTouchEnd() {
                dragging.value = '';
                zooming.value = '';
            }

            function calcBar(x: number) {
                let pos = x / width.value;

                // zoom viewport
                let zs = zoomStart.value;
                let ze = zoomEnd.value;
                const zw = ze - zs;

                // zooming
                // check if touch happed on one of the two drag ends
                const pxs = zs * width.value ;
                const pxe = ze * width.value;
                const pxp = 10 / width.value;
                if ((zooming.value == '' && x >= pxs && x <= pxs+20) || zooming.value == 'left') {
                    zooming.value = 'left';
                    zoomStart.value = Math.min(Math.max(pos - pxp, 0), ze-4*pxp);
                }else if ((zooming.value == '' && x <= pxe && x >= pxe-20) || zooming.value == 'right') {
                    zooming.value = 'right';
                    zoomEnd.value = Math.min(Math.max(pos + pxp, zs+4*pxp), 1);
                }else {
                    zooming.value = 'move';
                }


                // moving
                if (zooming.value == 'move') {
                    zs = Math.min(Math.max(pos-zw/2, 0), 1-zw);
                    ze = Math.min(zs+zw, 1);
                    zoomStart.value = Math.max(zs, 0);
                    zoomEnd.value = Math.min(ze, 1);
                }
            }

            function strToSeconds(dateString: string) {
                //const dateString = "0 days 00:00:00";

                // Split the string into parts
                const parts = dateString.split(" ");

                // Extract the days, hours, minutes, and seconds
                const days = parseInt(parts[0]);
                const timeParts = parts[2].split(":");
                const hours = parseInt(timeParts[0]);
                const minutes = parseInt(timeParts[1]);
                const seconds = parseInt(timeParts[2]);

                // Calculate the total number of seconds
                const totalSeconds = (days * 24 * 60 * 60) + (hours * 60 * 60) + (minutes * 60) + seconds;
                return totalSeconds
            }

            const currentTimeStr = computed(() => {
                const seconds = currentTime.value;
                const hours = Math.floor(seconds / 3600);
                const minutes = Math.floor((seconds % 3600) / 60);
                const remainingSeconds = Math.floor(seconds % 60);

                const formattedHours = String(hours).padStart(2, '0');
                const formattedMinutes = String(minutes).padStart(2, '0');
                const formattedSeconds = String(remainingSeconds).padStart(2, '0');

                return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
            });

            function calculateRatioString(num1:number, num2:number): string[] {
                const div = num1 + num2; //gcd(num1, num2);
                const r1 = num1 / div * 100;
                const r2 = 100 - r1;


                return [`${r1.toFixed(0)}%`, `${r2.toFixed(0)}%`];
            }

            function gcd(a: number, b: number): number {
                if (b == 0) {
                    return a;
                } else {
                    return gcd(b, a % b);
                }
            }

            function zoom(step: number) {
                if (step >= 1) {
                    zoomStart.value = 0;
                    zoomEnd.value = 1;
                }else {
                    // calc new zoom viewport
                    let zw = (zoomEnd.value - zoomStart.value);
                    let z = zw + step;
                    z = Math.min(Math.max(z, 0.0), 1.0);


                    // expand 50% to both sides, if possible
                    let zLeft = zoomStart.value - (z-zw)/2;
                    let zRight = zoomEnd.value + (z-zw)/2;

                    zoomStart.value = Math.min(Math.max(zLeft,0), zRight-0.05);
                    zoomEnd.value = Math.max(zLeft+.05, Math.min(zRight,1));
                }
            }











            function playpause() {
                if(video.value) {
                    if (video.value.paused) {
                        video.value.play();
                    } else {
                        video.value.pause();
                    }
                }

                if(videoRight.value) {
                    if (videoRight.value.paused) {
                        videoRight.value.play();
                    } else {
                        videoRight.value.pause();
                    }
                }
            }

            function reset() {
                jumpVideoTo(0);
                currentRetailData.value = {time: 0, ids: ''};
                retailDataCurrent.value = 0;
                retailDataToday.value = 0;
                retailDataCustomers.value = [];
                calcRetailStats();
            }

            function skip(t: number) {
                if(video.value) {
                    const nt = Math.min(Math.max(0, currentTime.value+t), maxTime.value)
                    video.value.currentTime = nt;

                    if(videoRight.value) {
                        videoRight.value.currentTime = nt;
                    }
                }
            }

            function setVolume(v: number) {
                // -1: toggle
                if (v == -1) {
                    v = volume.value == 0 ? 100 : 0;
                }
                volume.value = v;
            }

            function drawRoundRect (
                context: CanvasRenderingContext2D,
                x: number, y: number, rw: number, rh: number, radius: number,
                col: string, fill=false) {

                    if (rw < 2 * radius) radius = rw / 2;
                if (rh < 2 * radius) radius = rh / 2;
                context.beginPath();
                context.moveTo(x + radius, y);
                context.arcTo(x + rw, y, x + rw, y + rh, radius);
                context.arcTo(x + rw, y + rh, x, y + rh, radius);
                context.arcTo(x, y + rh, x, y, radius);
                context.arcTo(x, y, x + rw, y, radius);
                context.closePath();
                if (fill) {
                    context.fillStyle = col;
                    context.fill();
                }else {
                    context.strokeStyle = col;
                    context.stroke();
                }
            }

            watch(volume, (newValue, oldValue) => {
                if(video.value) {
                    video.value.volume = volume.value / 100;
                }
                if(videoRight.value) {
                    videoRight.value.volume = volume.value / 100;
                }
            });

            watch(currentRetailData, (newValue, oldValue) => {

                // on new retail data: calculate current and total number of entries
                calcRetailStats();
            });

            return {
                mapCanvas,
                mapCtx,
                mapW,
                mapH,
                retailAreas,
                retailDataLoaded,
                currentRetailData,
                calcRetailStats,
                retailDataCurrent,
                retailDataToday,
                retailDataCustomers,

                canvas,
                barCanvas,
                ctx,
                barCtx,
                video,
                videoRight,
                width,
                height,
                widthOffset,
                animationFrameId,
                animationTimeBuf,
                animationTimeCount,
                dragging,
                currentData,
                dataLoaded,
                zoomStart,
                zoomEnd,
                redraw,
                animate,
                handleCanvasTap,
                jumpVideoTo,
                onTouch,
                onTouchStart,
                onTouchMove,
                onTouchEnd,
                drawGraph,
                strToSeconds,
                calculateRatioString,
                gcd,
                calcBar,
                zooming,
                zoom,
                playpause,
                currentTimeStr,
                isPlaying,
                skip,
                setVolume,
                volume,
                showRightVideo,
                videoUrl,
                rightVideoUrl,
                loadConfig,
                configLoaded,
                onVideoLoaded,
                reset
            };
        }
    });
</script>

<style lang="scss" scoped>
    @import "./main.scss";
</style>

